@import url(https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@400;500;700&family=Barlow:wght@500&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.gradient {
  background: rgb(86, 84, 82);
  background: linear-gradient(
    180deg,
    rgba(86, 84, 82, 0.9471989479385504) 70%,
    rgba(86, 84, 82, 0) 100%
  );

  z-index: 1600 !important;
}
.solidBackground {
  background: rgb(86, 84, 82);
}
.pointer {
  cursor: pointer;
}
.accent {
  color: #fea907;
}
.loader {
  height: 5px;

  color: red;
  background-color: #fea907;
  margin-top: 10px;
}

::selection {
  color: rgb(86, 84, 82);
  background: #fea907;
}

